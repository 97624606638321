@import "src/theme/colors.module";

.paymentItem {
  display: flex;

  .icon {
    width: 29px;
    margin-right: 15px;
  }

  .dataContainer {
    display: flex;
    flex: 1;
    justify-content: space-between;

    .data {
      display: flex;
      row-gap: 4px;
      flex-direction: column;
      font-size: 16px;
      color: $color-gray-100;

      .title {
        font-weight: 500;
      }

      .description {
        font-weight: 300;
      }

      .paymentDate, .bankAccountNumber {
        font-size: 12px;
        font-weight: 300;
      }

      &.expired {
        .title {
          color: $color-red-100;
        }

        .paymentDate {
          color: $color-red-100;
        }
      }
    }

    .amount {
      display: flex;
      align-items: center;
      white-space: nowrap;

      .arrow {
        margin-left: 15px;
      }
    }
  }


}
